import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, MeshTransmissionMaterial, ContactShadows, Environment } from '@react-three/drei'
import { easing } from 'maath'
import { useStore } from './store'

export default function App() {
  return (
    <Canvas eventSource={document.getElementById('root')} eventPrefix="client" camera={{ position: [0, 0, 5], fov: 40 }}>
      <ambientLight intensity={0.7} />
      <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, -5]} castShadow />
      <Environment preset="city" background blur={1} />
      <ContactShadows resolution={512} position={[0, -0.8, 0]} opacity={1} scale={10} blur={2} far={0.8} />
        <Shoe rotation={[0.3, Math.PI / 20, 0]} />
    </Canvas>
  )
}

function Selector({ children }) {
  const ref = useRef()
  const store = useStore()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store.open ? 4 : 0.01, store.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store.open ? '#f0f0f0' : '#ccc', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref}>
        <circleGeometry args={[1, 64, 64]} />
        <MeshTransmissionMaterial samples={16} resolution={512} anisotropy={1} thickness={0.1} roughness={0.4} toneMapped={true} />
      </mesh>
      <group
        onPointerOver={() => (store.open = true)}
        onPointerOut={() => (store.open = false)}
        onPointerDown={() => (store.open = true)}
        onPointerUp={() => (store.open = false)}>
        {children}
      </group>
    </>
  )
}

/*
Title: Chair
*/

function Shoe(props) {
  const ref = useRef()
  const { nodes, materials } = useGLTF("/chair_model.glb");
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    // ref.current.rotation.set(Math.cos(t / 4) / 8, Math.sin(t / 3) / 4, 0.15 + Math.sin(t / 2) / 8)
    // ref.current.position.x = (0.5 + Math.cos(t / 0.8)) / 7
    ref.current.rotation.set(9.6, t*1.1, 3.1)
    ref.current.position.y = (0.5 + Math.cos(t / 0.8)) / 7
  })
  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0, 0]} scale={1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group21.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group20.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group19.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group23.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group22.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group12.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group11.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group10.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group14.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group13.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group5.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group4.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group3.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group7.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group6.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group8.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group3.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group2.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group35.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group5.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group4.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group30.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group29.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group28.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group25.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group24.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group26.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group2.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group6.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ZBrush_defualt_group.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group8.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group7.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group16.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group15.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group18.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group17.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group10.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes._Group9.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group32.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group31.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group34.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group33.geometry}
          material={materials.lambert112SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Group61531.geometry}
          material={materials.lambert112SG}
        />
      </group>
    </group>
  )
}

useGLTF.preload("/chair_model.glb");
